.Label {
  position: relative;
  display: block;
}

.Label input {
  padding: 10px 30px;
  border-bottom: 1px solid var(--white);
  height: 58px;
  margin-bottom: 4px;
}

.Label input::placeholder {
  color: var(--gray-100);
  text-transform: uppercase;
}

.Label input:focus,
.Label input:hover {
  border-bottom-color: var(--primary-green);
}

.Label input:disabled {
  border-bottom-color: var(--gray-300);
}

.Label input:disabled::placeholder {
  color: var(--gray-300);
}

.Label.Error input {
  border-bottom-color: var(--red);
}

.Label small {
  font-size: 12px;
  line-height: 16px;
}

.ErrorMsg {
  color: var(--red);
}

.Description {
  color: var(--gray-300);
}
