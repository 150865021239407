.GuaranteeList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 48px;
}

.Guarantee {
  padding-top: 24px;
  display: flex;
  gap: 24px;
  border-top: 1px solid var(--primary-green);
}

.Guarantee > h3 {
  color: var(--primary-green);
}

@media (max-width: 1439px) {
  .GuaranteeList {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

@media (max-width: 1023px) {
  .GuaranteeList {
    grid-template-columns: 1fr 1fr;
    row-gap: 48px;
  }
  .Guarantee:nth-child(3) {
    grid-column: 1/3;
  }
}

@media (max-width: 767px) {
  .GuaranteeList {
    grid-template-columns: 1fr;
    row-gap: 36px;
  }

  .Guarantee:nth-child(3) {
    grid-column: 1/2;
  }
}
