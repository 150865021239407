.Wrapper {
  background-color: var(--gray-400);
  z-index: 12;
  position: sticky;
  top: 0;
  transition: .5s top;
}

.Wrapper[data-hidden="true"] {
  top: calc(-1 * var(--header-height));
}

.Container {
  position: static;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--white);
  height: var(--header-height);
}

.Logo {
  display: block;
  height: 60px;
}

.Logo svg {
  height: 100%;
  object-fit: contain;
}

.Burger {
  display: none;
}

.Contacts {
  margin-left: auto;
}

@media screen and (max-width: 1439px) {
  .Burger {
    display: block;
    margin-left: 40px;
    height: 40px;
    width: 40px;
  }

  .Logo {
    margin-right: auto;
  }
}

@media screen and (max-width: 1023px) {
  .Logo {
    height: 50px;
  }
}

@media screen and (max-width: 767px) {
  .Burger {
    margin-left: 24px;
  }

  .Logo {
    height: 30px;
  }

  .ModalButton {
    display: none;
  }
}
