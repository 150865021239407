.Wrapper {
  display: grid;
  gap: 20px;
  grid-template-columns: 4fr 6fr 2fr;
  padding-top: 72px;
}

.Content > *:first-child {
  margin-bottom: 8px;
}

.Content > *:nth-child(n):not(:first-child) {
  padding-top: 24px;
}

.Content > h2:nth-child(n):not(:first-child) {
  padding-top: 40px;
}

.Content ul {
  list-style: initial;
  padding-left: 26px;
}

.Link {
  color: var(--primary-green);
}

.Content > *:nth-child(2) {
  list-style: none;
  padding: 0;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  display: none;
}

.Content > h1 {
  width: 135%;
}

.Content > h3 {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 1439px) {
  .Wrapper {
    grid-template-columns: 3fr 8fr 1fr;
  }

  .Content > *:nth-child(2) {
    display: flex;
  }

  .Content > h1 {
    width: 120%;
  }
}

@media screen and (max-width: 1023px) {
  .Wrapper {
    grid-template-columns: 100%;
    gap: 32px;
    padding-top: 48px;
  }

  .Content > h1 {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .Wrapper {
    gap: 24px;
    padding-top: 36px;
  }

  .Content > *:first-child {
    margin-bottom: 0;
  }
}
