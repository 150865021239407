.Section {
  display: grid;
  grid-template-columns: 4fr 1fr 5fr 2fr;
  gap: 40px 20px;
}

.Section p {
  grid-column-start: 3;
  padding-top: 40px;
}

.SmallImg {
  grid-column-start: 1;
}

.Img {
  grid-column: 3/5;
}

@media screen and (max-width: 1023px) {
  .Section {
    grid-template-columns: 5fr 7fr;
  }

  .Section p {
    grid-column-start: 2;
  }

  .SmallImg {
    display: none;
  }

  .Img {
    grid-column: 1/3;
  }
}

@media screen and (max-width: 1023px) {
  .Section {
    grid-template-columns: 7fr 1fr;
  }

  .Section p {
    padding-top: 0;
    grid-column-start: 1;
  }
}

@media screen and (max-width: 767px) {
  .Section {
    grid-template-columns: 1fr;
    gap: 32px;
  }

  .Img {
    grid-column: 1/1;
  }
}
