.Title {
  padding: 40px 0;
}

.Wrapper {
  display: grid;
  grid-template-columns: 4fr 8fr;
  gap: 32px 20px;
  align-items: flex-start;
  position: relative;
  height: 868px;
}

.PointsAside {
  background-color: var(--black);
  padding: 24px;
  height: 100%;
  width: 100%;
  grid-row: 1/3;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.AsideTitle {
  margin-bottom: 24px;
}

.AsideTitle button {
  display: none;
}

.Filters {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 32px;
}

.Filters > div {
  max-width: 360px;
}

.Filters > ul {
  margin-left: auto;
}

.ResetButton {
  width: fit-content;
  color: var(--white);
  margin: 14px 0 0 auto;
}

@media screen and (max-width: 1439px) {
  .Title {
    padding-bottom: 32px;
  }

  .Wrapper {
    grid-template-columns: 100%;
    gap: 32px;
    height: auto;
  }

  .PointsAside {
    position: absolute;
    z-index: 2;
    bottom: 24px;
    left: 0;
    height: auto;
    top: calc(78px + 24px);
    max-width: 453px;
  }
}

@media screen and (max-width: 1023px) {
  .PointsAside {
    max-width: 288px;
  }
}

@media screen and (max-width: 767px) {
  .Title {
    padding-top: 32px;
    padding-bottom: 24px;
  }

  .Wrapper {
    flex-direction: column-reverse;
    display: flex;
  }

  .PointsAside {
    max-width: 100%;
    position: static;
    padding: 16px;
    height: 569px;
  }

  .AsideTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .AsideTitle button {
    display: block;
  }

  .Filters {
    flex-direction: column-reverse;
    gap: 24px;
    align-items: flex-start;
  }

  .Filters > div {
    max-width: 100%;
  }
}
