.Container {
  background-color: rgba(0, 0, 0, .7);
}

.Wrapper {
  display: flex;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Close {
  margin-top: -36px;
  margin-right: -36px;
}

.Close svg {
  width: 24px;
  height: 24px;
}

.Close svg path {
  fill: var(--primary-green);
}

.Content {
  flex-grow: 1;
}

@media screen and (max-width: 767px) {
  .Close {
    margin-top: 0;
    margin-right: 0;
  }
}
