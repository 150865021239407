.Section {
  display: grid;
  grid-template-columns: 5fr 4fr 3fr;
  gap: 20px;
  width: 100%;
}

.Content {
  width: 100%;
}

.FirstImage {
  width: 100%;
  max-width: 453px;
}

.FirstImage picture {
  display: flex;
  height: 100%;
}

.SecondImage {
  width: 100%;
  max-width: 335px;
}

@media screen and (max-width: 1439px) {
  .Section {
    grid-template-columns: 8fr 4fr;
  }

  .SecondImage {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .Section {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .FirstImage {
    width: 100%;
    max-width: unset;
  }
}

@media screen and (max-width: 767px) {
  .FirstImage {
    display: none;
  }
}
