.Card:first-child {
  grid-row-start: span 3;
}

.Card:not(:first-child) {
  display: grid;
  grid-template-columns: 0.38fr 1fr;
  grid-template-rows: 100%;
  gap: 20px;
}

.Picture {
  display: block;
  height: 383px;
}

.Card:first-child .Picture {
  margin-bottom: 12px;
}

.Card:not(:first-child) .Picture {
  height: 100%;
}

.Picture img {
  object-position: center;
}

.Content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Content h4 {
  margin-top: 8px;
}

.Card:not(:first-child) .Content h4 {
  order: -1;
  margin-bottom: auto;
}

.Date {
  color: var(--gray-200);
}

@media screen and (max-width: 1439px) {
  .Card:not(:first-child) {
    grid-template-columns: 225px 1fr;
  }
}

@media screen and (max-width: 1023px) {
  .Card:first-child {
    grid-column-start: span 2;
    grid-row-start: initial;
  }

  .Card:not(:first-child) {
    display: block;
  }

  .Picture {
    margin-bottom: 12px;
    height: 440px;
  }

  .Card:not(:first-child) .Picture {
    height: 229px;
  }

  .Card:not(:first-child) .Content h4 {
    order: 0;
  }
}

@media screen and (max-width: 767px) {
  .Card:nth-child(n) {
    grid-column-start: initial;
  }

  .Card:nth-child(n) .Picture {
    height: 193px;
  }
}
