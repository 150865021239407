.Section {
  padding-top: 40px;
}

.Wrapper {
  display: grid;
  grid-template-columns: 453px auto;
  gap: 20px;
}

.Aside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Button {
  margin-top: 20px;
  width: 100%;
}

.Title {
  margin-bottom: 40px;
}

@media screen and (max-width: 1439px) {
  .Wrapper {
    grid-template-columns: minmax(0, 1fr);
    gap: 50px;
  }

  .Button {
    margin-top: 40px;
    width: 450px;
  }
}

@media screen and (max-width: 1023px) {
  .Button {
    margin-top: 10px;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .Wrapper {
    gap: 24px;
  }

  .Button {
    margin-top: 24px;
  }
}
