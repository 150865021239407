.Section {
  margin-bottom: 140px;
}

.Text {
  display: grid;
  grid-auto-flow: row;
  gap: 32px;
  
  max-width: 1045px;

  margin-bottom: 140px; 
}

.Picture {
  width: 100%;
}

@media (max-width: 1439px) {
  .Section {
    margin-bottom: 75px;
  }

  .Text {
    max-width: 797px;

    margin-bottom: 64px; 
  }
}

@media (max-width: 1023px) {
  .Section {
    margin-bottom: 64px;
  }
}

@media (max-width: 767px) {
  .Section {
    margin-bottom: 48px;
  }

  .Text {
    gap: 24px;

    margin-bottom: 48px;
  }
}
