.Section {
  margin-top: 96px;
  margin-bottom: 120px;
  width: 100%;

  display: grid;
  grid-auto-flow: row;
  align-items: flex-start;
  gap: 65px;
}

.Text {
  max-width: 744px;
  white-space: break-spaces;
}

.Image {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1439px) {
  .Text {
    max-width: 750px;
  }
}

@media screen and (max-width: 1023px) {
  .Section {
    margin-top: 64px;
    margin-bottom: 80px;

    gap: 77px;
  }

  .Text {
    max-width: 651px;
  }
}


@media screen and (max-width: 767px) {
  .Section {
    margin-top: 40px;

    gap: 40px;
  }
}
