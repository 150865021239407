.PressRelease {
  display: grid;
  grid-template-columns: 7fr 1fr 4fr;
  gap: 20px;
  align-items: flex-start;
}

.Main {
  grid-column: 1;
  width: 100%;
}

.Image {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
}

.TextContent {
  max-width: 86%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 32px;
  margin-bottom: 26px;
}

.SwiperWrap {
  width: 100%;
  display: grid;
  max-width: 100%;
}

.Swiper {
  width: 100%;
  padding-bottom: 28px;
}

.SlideImage,
.SlideImage picture {
  height: 100%;
  display: flex;
}

.Image picture,
.SlideImage picture {
  width: 100%;
}

.Text {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  align-items: flex-start;
  text-align: left;
}

.Text ul {
  flex-direction: column;
  gap: 18px;
  width: 100%;
  align-items: flex-start;
  list-style: disc;
  display: flex;
  padding: 0 0 14px 40px;
}

.Text a {
  color: var(--primary-green);
}

@media(max-width: 1439px) {
  .TextContent {
    max-width: 100%;
  }
}

@media(max-width: 1023px) {
  .PressRelease {
    display: block;
  }

  .Text ul {
    padding: 0 0 14px 32px;
  }
}

@media(max-width: 767px) {
  .Image {
    margin-bottom: 32px;
  }

  .TextContent {
    margin-bottom: 18px;
  }

  .Text ul {
    padding: 0 0 14px 24px;
  }
}
