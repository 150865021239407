.Section {
  display: flex;
  gap: 20px;
  padding-top: 40px;
  align-items: flex-start;
}

.Center {
  align-items: center;
}

.Title {
  padding-bottom: 32px;
}

.Text,
.TextBlock {
  width: calc(5/12 * 100% - 10px);
  flex-shrink: 0;
}

.Text > *:not(:first-child) {
  margin-bottom: 40px;
}

.Img {
  width: calc(7/12 * 100% - 10px);
  flex-shrink: 0;
}

@media screen and (max-width: 1439px) {
  .Text {
    padding-right: 80px;
  }

  .TextBlock {
    padding-right: 0;
  }
}

@media screen and (max-width: 1023px) {
  .Section {
    flex-direction: column;
    align-items: flex-start;
    gap: 46px;
  }

  .Text,
  .TextBlock {
    padding-right: 0;
    width: calc(6 / 8 * 100% - 10px);
  }

  .Img {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .Section {
    gap: 32px;
  }

  .Text,
  .TextBlock {
    width: 100%;
    padding-right: 0;
  }

  .Text > *:not(:first-child) {
    margin-bottom: 32px;
  }
}
