.Wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.Headline {
  display: grid;
  grid-template-columns: 1fr 24px;
  text-align: left;
}

.Button {
  padding: 4px;
}

.Button svg {
  width: 24px;
  height: 24px;
}

.Title {
  display: grid;
  grid-template-columns: 32px 1fr;
  align-items: center;
  gap: 16px;
}

.Info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.Card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  border: 1px solid var(--primary-green);
}

.Card > div:not(:first-child) {
  padding-top: 24px;
  border-top: 1px solid var(--gray-300);
}

.CardCharacteristic {
  align-items: center;
  gap: 12px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.CardCharacteristic > p {
  font-weight: 700;
}

.CardCharacteristic > p:first-child {
  color: var(--gray-200);
}

.CardCharacteristic > p:last-child {
  text-align: right;
}

@media (max-width: 1439px) {
  .Info {
    gap: 20px;
  }
}

@media (max-width: 1023px) {
  .Info {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .Wrap {
    gap: 20px;
  }

  .Card {
    padding: 20px;
  }

  .CardCharacteristic {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .CardCharacteristic > p:last-child {
    margin-left: auto;
  }
}
