.TabWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
}

.TabTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--white);
  transition: border 0.4s;
  cursor: pointer;
}

.Open {
  border-bottom-color: var(--primary-green);
}

.Icon {
  width: 56px;
  height: 56px;
  transition: transform 0.4s, stroke -0.4s;
}

.Active {
  transform: rotate(45deg);
}

.Active svg path {
  stroke: var(--primary-green);
}

.BottomBlock {
  width: 100%;
}
