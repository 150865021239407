.Section {
  display: flex;
  flex-direction: column;
  
  margin-top: 72px;
}

.Title {
  margin-bottom: 32px;
}

.Card {
  width: 100%;
  display: grid;
  grid-template-columns: 57.7% auto;
  grid-template-rows: 523px;

  gap: 22px;
}

.SwiperWrapper {
  position: relative;
}

.Swiper {
  width: 100%;
  height: 100%;
  position: static;
}

.RightContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TopContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.TopContent > h4,
.Price {
  font-weight: 700;
  display: flex;
  align-items: center;
}

.Price > svg {
  width: 32px;
  height: 40px;
  fill: var(--white);
}

.Sale {
  width: fit-content;
  background-color: var(--white);
  padding: 10px 20px;
  color: var(--gray-400);
  font-weight: 700;
}

.Properties > p {
  white-space: break-spaces;
  line-height: 32px;
}

.Image {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1439px) {
  .Card {
    grid-template-rows: 354px;
    gap: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .Card {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: 417px auto;
    gap: 66px;

    height: fit-content;
  }

  .RightContent {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
  }

  .Price > svg {
    width: 24px;
    height: 24px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .ModalButton {
    grid-column: 1 / 3;
  }
}

@media screen and (max-width: 767px) {
  .Card {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: 182px auto;
    gap: 60px;

    height: fit-content;
  }

  .Swiper {
    --swiper-navigation-top-offset: 55%;
  }

  .RightContent {
    grid-template-columns: minmax(0, 1fr);
  }

  .Sale {
    width: 100%;
    padding: 10px 20px;
  }
}