.gallery .swiper-pagination {
  bottom: 0px;
}

.gallery .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background: var(--white);
  opacity: 0.4;
}

.gallery .swiper-pagination-bullet-active {
  width: 20px;
  border-radius: 4px;
  opacity: 1;
}

.slider .swiper-pagination-bullet {
  width: 64px;
  height: 2px;
  opacity: .3;
  
  --swiper-pagination-color: var(--white);
  --swiper-pagination-bullet-inactive-color: var(--white);
  --swiper-pagination-bullet-border-radius: 0;
}

.slider .swiper-pagination-bullet-active {
  opacity: 1;
}

.slider .swiper-button-prev,
.slider .swiper-button-next {
  width: 32px;
  height: 32px;
}

.slider .swiper-button-prev:after,
.slider .swiper-button-next:after {
  content: initial;
}

@media screen and (max-width: 767px) {
  .slider .swiper-button-prev,
  .slider .swiper-button-next {
    width: 14px;
    height: 14px;
  }
}