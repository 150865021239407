.TopBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 80px;
}

.Title {
  width: calc(50% - 10px);
  padding: 16px 0;
  border-bottom: 1px solid var(--primary-green);
}

.TopTextBlock {
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 1fr 5fr 1fr;
  gap: 20px;
  align-items: flex-start;
}

.TextBlocks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  grid-column: 1;
}

.TextBox {
  width: 100%;
  grid-column: 3;
}

.CardBlocks {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.Card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 20px;
  border: 1px solid var(--primary-green);
}

.ImageBlock {
  width: 100%;
  height: 100%;
  display: flex;
  grid-row: 2;
  grid-column: 1;
}

.ImageBlock img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1439px) {
  .Title {
    width: calc(50% - 10px);
    line-height: 24px;
  }

  .TopTextBlock {
    grid-template-columns: 5fr 1fr 6fr;
  }

  .CardBlocks {
    gap: 32px 20px;
  }
}

@media screen and (max-width: 1023px) {
  .Title {
    width: calc(75% - 5px);
    line-height: 24px;
  }

  .TopTextBlock {
    width: calc(75% - 5px);
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .TextBlocks {
    grid-column: 1;
  }

  .TextBox {
    grid-column: 1;
  }

  .CardBlocks {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .ImageBlock {
    grid-row: 3;
    grid-column: 1;
  }
}

@media screen and (max-width: 767px) {
  .TopBlock {
    gap: 40px;
    margin-bottom: 64px;
  }

  .Title {
    width: 100%;
    font-size: 22px;
    line-height: 22px;
  }

  .Title br {
    display: none;
  }

  .TopTextBlock {
    width: 100%;
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .CardBlocks {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .Card {
    padding: 16px;
  }

  .TextBlocks >p:first-child {
    font-size: 20px;
    line-height: 24px;
  }
}
