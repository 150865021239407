.Line {
  display: block;
  background-color: rgba(250, 250, 250, 0.1);
  height: 1px;
}

.Margin {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .Margin {
    margin-bottom: 32px;
  }
}
