.Wrapper {
}

.Title {
  margin-bottom: 37px;
}

.RightBlock {
  min-height: 430px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Result-text {
  text-transform: uppercase;
}

.Success-content {
  display: grid;
  grid-auto-flow: row;
  row-gap: 63px;
  text-align: center;
}

.Success-check {
  width: 200px;
  height: 200px;
  justify-self: center;
}

.Fail-content {
  display: grid;
  grid-auto-flow: row;
  row-gap: 110px;
  text-align: center;
}

.Fail-button {
  width: 395px;
  justify-self: center;
}

.Form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-shrink: 0;
}

.Form > button {
  margin-top: 36px;
}

.Form label:first-child input,
.Form label:nth-child(2) input {
  height: 78px;
}

@media screen and (max-width: 1439px) {
  .Wrapper {
    padding-top: 40px;
  }
}

@media screen and (max-width: 1023px) {
  .Wrapper {
    gap: 20px;
  }
}

@media screen and (max-width: 767px) {
  .Wrapper {
    flex-direction: column;
    gap: 16px;
  }

  .RightBlock {
    width: 100%;
  }

  .Success-content {
    row-gap: 25px;
  }

  .Fail-content {
    row-gap: 37px;
  }

  .Fail-button {
    width: 100%;
  }

  .Title {
    width: 100%;
    margin-bottom: 24px;
  }

  .Success-check {
    width: 130px;
    height: 130px;
  }

  .Form > button {
    margin-top: 24px;
  }
}
