.container {
  padding-right: var(--padding-container);
  padding-left: var(--padding-container);
  width: 100%;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  z-index: 2;
}

.section {
  margin-bottom: 160px;
}

.section-m {
  margin-bottom: 120px;
}

@media screen and (max-width: 767px) {
  .section {
    margin-bottom: 120px;
  }

  .section-m {
    margin-bottom: 80px;
  }
}
