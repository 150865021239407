.Navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 12px;
}

.Navigation button {
  width: 56px;
  height: 56px;
}

.Navigation button:first-child {
  transform: rotate(180deg);
}

.Navigation button:disabled svg path {
  stroke: var(--gray-300);
}

@media screen and (max-width: 767px) {
  .Navigation {
    margin-top: 32px;
  }
}
