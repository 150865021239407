.Label {
  position: relative;
  display: block;
  color: var(--white);
  width: 100%;
}

.Icon {
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 19px;
}

.BackIcon {
  display: none;
}

.BackIcon,
.SearchIcon {
  left: 19px;
}

.ClearIcon {
  right: 19px;
  cursor: pointer;
}

.Input {
  background: transparent;
  line-height: 24px;
  padding: 19px 53px;
  border: 1px solid var(--gray-200);
  border-radius: 50px;
}

.Input:focus {
  border-color: var(--primary-green);
}

.Input::placeholder {
  color: inherit;
}

@media screen and (max-width: 767px) {
  .LabelHeader .SearchIcon {
    left: initial;
    right: 19px;
  }

  .LabelHeader .ClearIcon {
    display: none;
  }

  .LabelHeader .BackIcon {
    display: block;
  }

  .LabelHeader .BackIcon svg {
    transform: rotate(180deg);
  }
}
