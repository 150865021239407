.Section {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.Section {
  gap: 40px;
}

.TypesList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px 20px;
  align-items: flex-start;
}

.TypesList > div:nth-child(3) {
  grid-column: 2;
}
.TypesList > div:nth-child(4) {
  grid-column: 2;
}

.Image {
  width: 100%;
  height: 100%;
  display: flex;
  grid-row: 1/3;
  grid-column: 1/2;
}

@media (max-width: 1439px) {
  .TypesList > div:nth-child(3) {
    grid-column: 1;
  }
  
  .Image {
    height: 100%;
    grid-row: 1/2;
    max-height: 296px;
  }
}

@media (max-width: 1023px) {
  .TypesList {
    grid-template-columns: 1fr;
  }

  .Image {
    max-height: 255px;
    grid-column: 1;
    grid-row: 2;
  }

  .TypesList > div:nth-child(2) {
    grid-row: 1;
    grid-column: 1;
  }

  .TypesList > div:nth-child(3) {
    grid-row: 3;
    grid-column: 1;
  }

  .TypesList > div:nth-child(4) {
    grid-column: 1;
    grid-row: 4;
  }
}

@media (max-width: 767px) {
  .Section {
    gap: 32px;
    margin-top: 32px;
  }

  .TypesList {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .Image {
    max-height: 210px;
  }
}
