.Wrapper {
  background-color: var(--primary-green);
  color: var(--black);
  display: grid;
  grid-auto-flow: column;
  gap: 139px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.Text h2 {
  margin-bottom: 76px;
  color: var(--black);
}

.Text p {
  white-space: pre-line;
  color: var(--gray-400);
}

.Text div {
  margin: auto 0;
  grid-column-start: 1;
}

.Link {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
  width: max-content;
}

.Link svg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.Link svg path {
  stroke: var(--black);
}

.Images {
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
}

.Images picture:first-child img {
  height: 541px;
  width: auto;
  background-color: rgba(0, 0, 0, 0.2);
}

.Images picture:last-child img {
  height: 209px;
  width: auto;
  background-color: rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1439px) {
  .Wrapper {
    gap: 110px;
  }

  .Section {
    padding: 0;
  }

  .Text h2 {
    margin-bottom: 64px;
  }

  .Images picture:first-child img {
    height: 389px;
  }

  .Images picture:last-child {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .Wrapper {
    grid-template-columns: 462px;
  }

  .Images {
    position: absolute;
    right: 0;
    top: 40px;
  }

  .Images picture:first-child img {
    height: 346px;
  }

  .Text div {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .Wrapper {
    padding-top: 24px;
    padding-bottom: 0;

    gap: 24px;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .Text h2 {
    margin-bottom: 24px;
  }

  .Images {
    position: static;
  }

  .Images picture:first-child {
    margin-right: calc(-1 * var(--padding-container));
    margin-left: calc(-1 * var(--padding-container));
  }

  .Images picture:first-child img {
    width: 100%;
    height: auto;
  }
}
