.Section {
  padding-top: 70px;
}

.Title {
  margin-bottom: 40px;
}

.MainInfo {
  margin-bottom: 24px;
}

.RequisitesButton {
  margin-bottom: 90px;

  width: 450px;
  height: 96px;
  white-space: pre-line;
  text-align: left;
  padding: 20px;
}

.RequisitesButton div {
  position: absolute;
  right: 24px;
  width: 48px;
  height: 48px;
}

.List {
  margin-bottom: 120px;
}

.List li {
  display: flex;
  justify-content: space-between;
}

.List li:not(:last-child) {
  margin-bottom: 90px;
}

.Button {
  width: 239px;
}

.Line {
  margin: 0;
}

@media screen and (max-width: 1439px) {
  .RequisitesButton {
    height: 88px;
  }

  .List li {
    align-items: flex-end;
  }
}

@media screen and (max-width: 1023px) {
  .RequisitesButton h4 {
    font-size: 24px;
  }

  .Button {
    position: absolute;
    right: var(--padding-container);
  }
}

@media screen and (max-width: 767px) {
  .RequisitesButton {
    width: 100%;
    height: 108px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    white-space: initial;
  }

  .RequisitesButton div {
    right: calc(100% - 55px);
    bottom: -15px;
  }

  .Button {
    display: none;
  }

  .List {
    margin-bottom: 90px;
  }
}
