.Section {
  padding-top: 72px;
}

.Wrapper {
  position: relative;
}

.News {
  display: grid;
  grid-template-columns: 5fr 7fr;
  grid-template-rows: repeat(3, 156px);
  gap: 20px;
  margin-top: 48px;
}

@media screen and (max-width: 1439px) {
  .News {
    margin-bottom: 24px;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1023px) {
  .News {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: initial;
    gap: 40px 20px;
  }
}

@media screen and (max-width: 767px) {
  .Section {
    padding-top: 42px;
  }

  .News {
    grid-template-columns: 1fr;
    margin-bottom: 20px;
    gap: 32px;
    margin-top: 36px;
  }
}
