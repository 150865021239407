.Wrapper {
  position: relative;
  border: 1px solid var(--primary-green);
  padding: 24px;
  transition: 0.3s background-color, 0.3s color;
  display: block;
}

.Background {
  background-color: var(--gray-400);
}

.Small {
  padding: 12px;
}

.Wrapper svg path {
  transition: 0.3s stroke, 0.3s fill;
}

.Wrapper:not(.Fill) svg path {
  stroke: var(--primary-green);
}

.Wrapper.Fill svg path {
  fill: var(--primary-green);
}

.Wrapper:hover {
  cursor: pointer;
  background-color: var(--primary-green);
  color: var(--black);
}

.Wrapper:not(.Fill):hover svg path {
  stroke: var(--black);
}

.Wrapper.Fill:hover svg path {
  fill: var(--black);
}
