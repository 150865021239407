.Card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  height: 409px;

  gap: 20px;
}

.LeftContent {
  height: inherit;
}

.LeftContent img {
  height: 100%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.2);
}

.RightContent {
  background-color: var(--black);
  padding: 40px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TopContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.TopContent > h4,
.Price {
  font-weight: 700;
  display: flex;
  align-items: center;
}

.Price > svg {
  width: 16px;
  height: 16px;
  fill: var(--white);
}

.Sale {
  width: fit-content;
  background-color: var(--white);
  padding: 10px 20px;
  color: var(--gray-400);
  font-weight: 700;
}

.Properties {
  white-space: pre-line;
}

.Properties p {
  line-height: 32px;
}

.Buttons {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 12px;
}

.Buttons a {
  height: 48px;
}

.Buttons svg {
  margin-left: 10px;
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 1439px) {
  .Card {
    min-height: 279px;
    height: initial;
  }

  .RightContent {
    padding: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .Card {
    grid-template-columns: 1fr;
    gap: 24px;

    height: fit-content;
  }

  .RightContent {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
  }

  .Price {
    font-size: 24px;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .Top {
    margin-bottom: -20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray-400);
  }

  .TopContent {
    flex-direction: column;
  }

  .Price {
    margin-top: 20px;
    font-size: 20px;
    line-height: 24px;
  }

  .Sale {
    width: 100%;
    padding: 10px;
  }

  .Buttons {
    grid-template-columns: 1fr min-content;
  }

  .Buttons a {
    height: 38px;
    min-width: 38px;
  }

  .Buttons svg {
    margin-left: 0;
    width: 30px;
    height: 30px;
  }

  .DownloadText {
    display: none;
  }
}
