.Container {
  width: 100%;
  word-break: break-word;

  & strong {
    font-weight: 600;
  }

  & p {
    color: white;
    width: 100%;
  }
  & ol {
    list-style: auto;
  }

  & ul {
    padding: 0 0 0 35px;
    list-style: disc;
  }
  & u {
    text-decoration: underline;
  }
}
