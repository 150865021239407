.TopBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
}

.TopText,
.BottomText {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.TopText {
  padding: 24px 0;
  padding-right: 118px;
}

.Green {
  color: var(--primary-green);
}

.TopText .Green {
  text-decoration: underline;
}

.BottomBlock {
  display: grid;
  grid-template-columns: 5fr 5fr 2fr;
  gap: 20px;
  align-items: center;
}

@media (min-width: 1440px) {
  .TopText > h3 {
    font-size: 30px;
  }
}

@media (max-width: 1439px) {
  .TopText {
    padding-right: 0;
  }

  .BottomBlock {
    grid-template-columns: 2fr 3fr;
  }
}

@media (max-width: 1023px) {
  .TopBlock,
  .BottomBlock {
    grid-template-columns: 1fr;
    gap: 32px;
  }

  .TopText,
  .BottomText {
    padding: 0;
    width: 87%;
  }

  .BottomText {
    order: -1;
  }
}

@media (max-width: 767px) {
  .TopBlock,
  .BottomBlock {
    gap: 24px;
  }

  .TopText,
  .BottomText {
    width: 100%;
  }

  .BottomText {
    gap: 20px;
  }
}
