html {
  font-family: Arial, sans-serif;
}

body {
  background-color: var(--gray-400);
  color: var(--gray-100);
  font-weight: 400;
  font-size: 16px;
  background-image: var(--noise);
  background-repeat: repeat;
  background-size: 100px;
}

picture {
  display: block;
}

:focus-visible {
  outline-color: var(--primary-green);
}

.img-cover {
  height: 100%;
  object-fit: cover;
}

.modal-open {
  overflow: hidden;
}
