.Section {
  display: grid;
  align-items: flex-start;
  gap: 32px 20px;
  grid-template-columns: 1fr 1fr;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}

.Img {
  width: 100%;
  flex-shrink: 0;
}

.Text {
  padding-right: 118px;
}

.Text a {
  display: block;
  margin-top: 8px;
}

@media screen and (max-width: 1439px) {
  .Section {
    grid-template-columns: 1fr 1fr;
  }

  .Content {
    gap: 132px;
  }

  .SubTitle {
    width: calc(67% - 7px);
  }

  .Text {
    padding-right: 0;
  }
}

@media screen and (max-width: 1023px) {
  .Section {
    grid-template-columns: 1fr;
  }

  .SubTitle {
    width: 100%;
  }

  .Content {
    height: auto;
    max-width: 470px;
    gap: 64px;
  }
}

@media screen and (max-width: 767px) {
  .Section {
    gap: 24px;
  }

  .Content {
    gap: 48px;
  }
}
