.Map {
  height: 722px;
  background-color: rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 767px) {
  .Map {
    height: 342px;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
