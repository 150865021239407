.modal-base-layout {
  position: fixed;
  inset: 0px;
  z-index: 9999;
  text-align: center;
}

.modal-base-layout::after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.modal-base-wrapper {
  display: inline-grid;
  text-align: left;
  vertical-align: middle;

  width: auto;
  height: auto;
}

.modal-base-container {
  position: relative;
  display: flex;
  flex-direction: column;

  height: fit-content;
}


@media screen and (max-width: 767px) {
  .modal-base-wrapper {
    width: 100%;
    height: 100%;
  }

  .modal-base-container {
    height: 100%;
  }
}