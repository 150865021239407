.Pagination {
  display: flex;
  gap: 4px;
  margin-top: 40px;
  justify-content: center;
}

.Btn {
  flex-shrink: 0;
  border: 1px solid var(--gray-300);
  height: 48px;
  width: 48px;
  transition: 0.3s border-color, 0.3s color, 0.3s background-color;
}

.Active {
  background-color: var(--primary-green-100);
  border-color: var(--primary-green-100);
  color: var(--black);
}

.Arrow:first-child {
  margin-right: 8px;
}

.Arrow:last-child {
  margin-left: 8px;
}

.Arrow > svg {
  width: 24px;
  height: 24px;
}

.Arrow:first-child svg {
  transform: rotate(180deg);
}

@media screen and (max-width: 767px) {
  .Pagination {
    margin-top: 32px;
  }

  .Btn {
    width: 32px;
    height: 32px;
  }
}
