#modal-root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#modal-root:empty {
  pointer-events: none;
}