.Section {
  margin-bottom: 212px;
}

.Title {
  max-width: 1045px;

  margin-bottom: 80px;
}

.Text {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 80px 23px;
}

.SubTitle {
  margin-bottom: 25px;
}

@media (max-width: 1439px) {
  .Section {
    margin-bottom: 64px;
  }

  .Title {
    margin-bottom: 67px;
  }

  .Text {
    gap: 63px 20px;
  }
}

@media (max-width: 1023px) {
  .Title {
    margin-bottom: 64px;
  }

  .Text {
    grid-template-columns: 1fr;
    gap: 48px;
  }
}

@media (max-width: 767px) {
  .Section {
    margin-bottom: 80px;
  }

  .Title {
    margin-bottom: 48px;
  }
}