.ListWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: flex-start;
}

.TextWrap {
  display: grid;
  grid-template-columns: 16px auto;
  gap: 16px;
  align-items: center;
}

.TextWrap svg {
  width: 16px;
  height: 16px;
}

.TextWrap p {
  color: var(--white);
}