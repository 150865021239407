.List {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 18px;
  width: 100%;
  overflow: auto;
}

.Item {
  background-color: var(--gray-400);
  color: var(--white);
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
}

.Item h6 {
  font-weight: 700;
}

.Item a {
  width: fit-content;
}

.Selected {
  background-color: var(--primary-green);
  color: var(--black);
}