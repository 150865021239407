.Section {
  grid-column: 3;
  width: 100%;
}

.Section > h4 {
  margin-bottom: 48px;
}

.List > li {
  display: flex;
  width: 100%;
}

.List {
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.LinkWrap {
  gap: 12px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--primary-green);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.TitleBox {
  display: grid;
  grid-template-columns: 1fr 24px;
  justify-content: space-between;
  align-items: start;
  gap: 16px;
}

.TitleBox > p {
  text-align: start;
}

@media (max-width: 1023px) {
  .Section {
    display: none;
  }
}
