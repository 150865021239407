.Section {
  margin-top: 96px;
  margin-bottom: 120px;
}

.Title {
  margin-bottom: 96px;
}

.Cards {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;

  grid-auto-rows: 353px;
}

@media (max-width: 1439px) {
  .Cards {
    grid-auto-rows: 250px;
  }
}

@media (max-width: 1023px) {
  .Section {
    margin-top: 80px;
  }

  .Title {
    margin-bottom: 80px;
  }

  .Cards {
    grid-auto-rows: 200px;
  }
}

@media (max-width: 767px) {
  .Section {
    margin-top: 64px;
    margin-bottom: 80px;
  }

  .Title {
    margin-bottom: 64px;
  }

  .Cards {
    grid-template-columns: minmax(0, 1fr);
  }
}
