.Wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 20px;
}

@media screen and (max-width: 1023px) {
  .Wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .Wrapper {
    grid-template-columns: 1fr;
  }
}
