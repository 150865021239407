.Wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  pointer-events: none;
}

.Wrapper[data-sticky="true"] {
  position: sticky;
  bottom: 20px;
  z-index: 10;
}


.Button {
  pointer-events: all;
  border-radius: 50%;
  background-color: var(--black);
  padding: 5px;
}

.Button svg {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 767px) {
  .Wrapper {
    margin-bottom: 30px;
  }
}