.Hero {
  margin-top: 20px;
  height: 667px;
  width: 100%;
  position: relative;
  margin-bottom: 120px;
  z-index: 7;
}

.Overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}

.FullText {
  width: 100%;
}

.Bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.Bg img {
  position: relative;
  object-position: center;
  z-index: 1;
}

.Content {
  position: relative;
  z-index: 2;
  padding: 64px 40px;
}

.Title {
  max-width: 926px;
  white-space: break-spaces;
}

@media screen and (max-width: 1439px) {
  .Hero {
    height: 460px;
  }

  .Content {
    padding: 40px;
  }
}

@media screen and (max-width: 1023px) {
  .Hero {
    height: 363px;
    margin-bottom: 60px;
  }

  .Bg {
    left: calc(-1 * var(--padding-container));
    width: 100vw;
  }

  .Content {
    padding: 22px 60px;
  }
}

@media screen and (max-width: 767px) {
  .Hero {
    height: 528px;
  }

  .Content {
    padding: 20px 16px;
  }
}
