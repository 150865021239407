.Tabs {
  display: flex;
  gap: 8px;
}

@media screen and (max-width: 767px) {
  .Tabs {
    width: 100%;
    overflow: auto;
  }
}
