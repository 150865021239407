.Hexagons {
  display: flex;
  justify-content: center;
  padding-bottom: 287px;
  overflow: hidden;
  position: relative;
}

.Hexagon {
  z-index: 1;
}

.Hexagon:first-child {
  transform: translateX(70%);
}

.Hexagon:nth-child(2) {
  transform: translate(35%, 90%);
}

.Hexagon:nth-child(4) {
  transform: translate(-35%, 90%);
}

.Hexagon:nth-child(5) {
  transform: translateX(-70%);
}

.HexagonLink {
  cursor: pointer;
  position: relative;
}

.HexagonLink,
.HexagonLink::before,
.HexagonLink::after {
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  width: 278px;
  height: 320px;
  display: block;
}

.HexagonLink,
.HexagonLink::before,
.HexagonLink::after,
.BackFirstHexagon,
.BackSecondHexagon {
  width: 278px;
  height: 320px;
}

.HexagonLink::before,
.HexagonLink::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s opacity;
}

.HexagonLink::before {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.HexagonLink::after {
  z-index: 2;
  background-color: var(--primary-green);
  opacity: 0;
}

.Title {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  z-index: 3;
  color: var(--gray-100);
  transition: 0.3s color;
}

.HexagonLink:hover .Title,
.HexagonLink:focus .Title {
  color: var(--black);
}

.HexagonLink:hover::after,
.HexagonLink:focus::after {
  opacity: 0.7;
}

.HexagonLink:hover::before,
.HexagonLink:focus::before {
  opacity: 0.5;
}

.BackFirstHexagon,
.BackSecondHexagon {
  display: block;

  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYzIiBoZWlnaHQ9IjMwMyIgdmlld0JveD0iMCAwIDI2MyAzMDMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yNjAuMjUgNzcuODcyN0wyNjAuMjUgNzYuNzE4TDI1OS4yNSA3Ni4xNDA3TDEzMi4zNzcgMi44OTA2N0wxMzEuMzc3IDIuMzEzMzJMMTMwLjM3NyAyLjg5MDY3TDMuNTA0NTYgNzYuMTQwN0wyLjUwNDU3IDc2LjcxOEwyLjUwNDU2IDc3Ljg3MjdMMi41MDQ1NSAyMjQuMzczTDIuNTA0NTcgMjI1LjUyN0wzLjUwNDU1IDIyNi4xMDVMMTMwLjM3NyAyOTkuMzU1TDEzMS4zNzcgMjk5LjkzMkwxMzIuMzc3IDI5OS4zNTVMMjU5LjI1IDIyNi4xMDVMMjYwLjI1IDIyNS41MjdMMjYwLjI1IDIyNC4zNzNMMjYwLjI1IDc3Ljg3MjdaIiBzdHJva2U9IiNDRkQwNEMiIHN0cm9rZS13aWR0aD0iNCIvPgo8L3N2Zz4K');
  background-repeat: no-repeat;

  position: absolute;
  top: 0;
  z-index: -1;

  user-select: none;
  pointer-events: none;
}

.BackFirstHexagon {
  top: 74%;
  right: -84%;
}

.BackSecondHexagon {
  top: 25%;
  right: -285%;
}

@media screen and (max-width: 1439px) {
  .Hexagons {
    padding-bottom: 274px;
  }

  .HexagonLink,
  .HexagonLink::before,
  .HexagonLink::after {
    width: 263px;
    height: 304px;
  }

  .BackFirstHexagon,
  .BackSecondHexagon {
    background-size: 280px 280px;
    width: 280px;
    height: 280px;
  }

  .BackFirstHexagon {
    top: 75%;
    right: -75%;
  }

  .BackSecondHexagon {
    top: 21%;
    right: -275%;
  }
}

@media screen and (max-width: 1023px) {
  .Hexagons {
    padding-bottom: 187px;
  }

  .Hexagon:first-child {
    transform: translateX(90%);
  }
  
  .Hexagon:nth-child(2) {
    transform: translate(45%, 80%);
  }
  
  .Hexagon:nth-child(4) {
    transform: translate(-45%, 80%);
  }
  
  .Hexagon:nth-child(5) {
    transform: translateX(-90%);
  }

  .HexagonLink,
  .HexagonLink::before,
  .HexagonLink::after {
    width: 203px;
    height: 234px;
  }

  .BackFirstHexagon,
  .BackSecondHexagon {
    background-size: 215px 215px;
    width: 215px;
    height: 215px;
  }

  .BackFirstHexagon {
    top: 66%;
    right: -67%;
  }

  .BackSecondHexagon {
    top: 21%;
    right: -242%;
  }
}

@media screen and (max-width: 767px) {
  .Hexagons {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .Hexagon {
    display: flex;
  }

  .Hexagon:first-child {
    grid-row: 2;
    grid-column: 1 / 3;
    justify-self: center;

    transform: initial;
  }

  .Hexagon:nth-child(2) {
    grid-row: 3;
    grid-column: 2;
    justify-self: flex-start;

    transform: translate(7%, -18%);
  }

  .Hexagon:nth-child(3) {
    grid-row: 1;
    grid-column: 2;
    justify-self: flex-start;

    transform: translate(7%, 18%);
  }

  .Hexagon:nth-child(4) {
    grid-row: 3;
    grid-column: 1;
    justify-self: flex-end;

    transform: translate(-7%, -18%);
  }

  .Hexagon:nth-child(5) {
    grid-row: 1;
    grid-column: 1;
    justify-self: flex-end;

    transform: translate(-7%, 18%);
  }

  .HexagonLink,
  .HexagonLink::before,
  .HexagonLink::after {
    width: 150px;
    height: 173px;
  }

  .BackFirstHexagon,
  .BackSecondHexagon {
    background-size: 158px 158px;
    width: 158px;
    height: 158px;
  }

  .BackFirstHexagon {
    top: 35%;
    right: 11%;
  }

  .BackSecondHexagon {
    top: 56%;
    right: 47%;
  }
}

