.Header {
  display: grid;
  grid-template-columns: 3fr 5fr 4fr;
  gap: 20px;
  margin-bottom: 48px;
}

.Header p {
  margin-top: 32px;
}

@media screen and (max-width: 1439px) {
  .Header {
    grid-template-columns: 3fr 7fr 2fr;
  }
}

@media screen and (max-width: 1023px) {
  .Header {
    grid-template-columns: 3fr 5fr;
  }

  .Header p {
    margin-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .Header {
    grid-template-columns: 100%;
    gap: 32px;
  }

  .Header p {
    margin-top: 0;
  }
}
