.ModalContainer {
  padding: 24px 20px 72px 20px;
}

.Close {
  margin: 0;
}

.Title {
  text-align: center;
  margin: 16px 0 32px;
  font-weight: 700;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Wrapper li {
  display: grid;
  grid-template-columns: 365px auto;
  text-align: left;
  
  white-space: pre-line;
}

.Wrapper li:not(:last-child) {
  margin-bottom: 16px;
}

.Key {
  color: var(--gray-200);
}

@media screen and (max-width: 1439px) {
  .Wrapper li {
    grid-template-columns: auto 478px;
  }
}

@media screen and (max-width: 1023px) {
  .Wrapper li {
    grid-template-columns: minmax(0, 1fr);
    gap: 16px;
  }

  .Key {
    font-size: 16px;
  }
}