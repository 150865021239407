.List {
  padding: 32px 0;
  background-color: var(--black);

  :not(:last-child) {
    border-bottom: 1px solid var(--gray-400);
  }
}

.SubButton {
  border-bottom: none !important;
  margin-bottom: 26px;
}

.SubButton,
.Item {
  text-transform: uppercase;
  white-space: normal;
}

.Item {
  padding: 16px 18px;
  display: flex;
}

.SubButton {
  padding: 13px 18px;
}

.SubButton svg {
  margin-right: 8px;

  width: 24px;
  height: 24px;
}