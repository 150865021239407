.Top {
  margin-bottom: 48px;
}

.Top > p {
  color: var(--gray-200);
  margin-left: calc(12px + 56px);
}

.Header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.Back {
  width: 56px;
  height: 56px;
  flex-shrink: 0;
  transform: rotate(180deg);
}

@media screen and (max-width: 767px) {
  .Top {
    margin-bottom: 36px;
  }

  .Top > p {
    margin-left: calc(12px + 24px);
  }

  .Back {
    width: 24px;
    height: 24px;
  }
}
