.Card {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 100%;
}

.Preview {
  height: 313px;
  display: block;
  flex-shrink: 0;
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 20px;
  padding: 11px;
  border: 1px solid var(--primary-green);
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 20px;
  padding: 11px;
  border: 1px solid var(--primary-green);
  transition: background 0.4s, color 0.4s;
}

.Content path {
  transition: stroke 0.4s;
}

.Content:hover {
  background: var(--primary-green);
  color: var(--black);
}

.Content:hover path {
  stroke: var(--black);
}

@media screen and (max-width: 1439px) {
  .Preview {
    height: 287px;
  }
}

@media screen and (max-width: 1023px) {
  .Preview {
    height: 297px;
  }
}

@media screen and (max-width: 767px) {
  .Preview {
    height: 270px;
  }
}
