.Hero {
  padding-bottom: 120px;
}

.Hexagons {
  position: relative;
  z-index: 10;
}

@media screen and (max-width: 1439px) {
  .WebsiteName {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .Hero {
    padding-bottom: 80px;
  }
}
