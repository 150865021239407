.Wrapper {
  padding-top: 24px;
  padding-bottom: 64px;
}

.Footer {
  display: grid;
  grid-template-columns: 6fr 7fr;
  gap: 20px;
}

.Logo {
  width: 144px;
}

.FooterLinks {
  text-transform: uppercase;
  display: grid;
  gap: 10px 90px;
  grid-template-columns: 1fr 1fr;
  white-space: nowrap;
}

.FooterLinks > li {
  width: calc(50% - 10px);
  line-height: 20px;
}

.Socials {
  display: grid;
  grid-auto-flow: column;
  width: fit-content;
  gap: 10px;

  margin-top: 80px;
}

.Social {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Social > a {
  display: block;
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 1023px) {
  .Footer {
    grid-template-columns: 2fr 5fr;
  }

  .FooterLinks li {
    width: initial;
  }

  .Socials {
    margin-top: 38px;

    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: initial;
  }
}

@media screen and (max-width: 767px) {
  .Wrapper {
    padding-bottom: 32px;
  }

  .Footer {
    grid-template-columns: 100%;
    gap: 32px;
  }

  .Logo {
    width: 72px;
  }

  .FooterLinks {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .FooterLinks > li {
    margin-left: 24px;
    width: initial;

    grid-row: var(--mobile-row, initial);
  }

  .Socials {
    margin-top: 144px;

    grid-auto-flow: column;
  }
}
