.List {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  color: var(--gray-300);
  max-width: 335px;
}

.List li {
  flex-shrink: 0;
}

@media (max-width: 1439px) {
  .List {
    padding: 0;
    max-width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 32px;
  }

  .List > li {
    max-width: fit-content;
  }

  @media (max-width: 767px) {
    .List {
      overflow: auto;
    }
    .List > li {
      max-width: 626px;
    }
  }
}
