.Title {
  padding-top: 40px;
  padding-bottom: 40px;
}

.Cards {
  display: grid;
  grid-template-columns: repeat(4, 3fr);
  column-gap: 20px;
}

.Cards-2 {
  grid-template-columns: repeat(3, 1fr);
}

.Cards-1 {
  grid-template-columns: repeat(2, 1fr);
}

.GuaranteeCards {
  row-gap: 20px;
  grid-template-rows: repeat(2, 200px);
  grid-template-columns: repeat(4, 1fr);
}

.Cards-0 {
  grid-template-columns: 100%;
  height: 433px;
}

.GuaranteeCards .Link {
  grid-row: 1/3;
  height: 100%;
}

.GuaranteeCards.Cards-2 {
  grid-template-columns: repeat(2, 1fr);
}

.GuaranteeCards.Cards-4 {
  grid-template-columns: repeat(3, 1fr);
}

.Link {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;
  height: 100%;
}

.GuaranteeCards:not(.Cards-0) .Link {
  grid-column: 4;
}

.GuaranteeCards.Cards-2 .Link {
  grid-column: 2;
}

.GuaranteeCards.Cards-4 .Link {
  grid-column: 3;
}

.Link::before,
.Picture {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.Link::before {
  content: "";
  z-index: 2;
  background-color: var(--primary-green);
  opacity: 0.8;
}

.Picture {
  display: block;
  height: 100%;
}

.Button {
  position: relative;
  z-index: 3;
  color: var(--black);
}

.Button svg {
  width: 32px;
  height: 32px;
}

.Button svg path {
  stroke: var(--black);
}

@media screen and (max-width: 1439px) {
  .Cards-3,
  .GuaranteeCards:not(.Cards-0) {
    grid-template-columns: repeat(3, 4fr);
  }

  .GuaranteeCards {
    grid-template-rows: repeat(2, 193px);
  }

  .Cards:not(.GuaranteeCards) > div:nth-child(3),
  .GuaranteeCards > div:nth-child(5),
  .GuaranteeCards > div:nth-child(6) {
    display: none;
  }

  .GuaranteeCards:not(.Cards-0) .Link {
    grid-column: 3;
  }
}

@media screen and (max-width: 1023px) {
  .Cards:not(.Cards-0),
  .GuaranteeCards.Cards-4 {
    grid-template-columns: repeat(2, 4fr);
  }

  .GuaranteeCards {
    grid-template-rows: repeat(2, 198px);
  }

  .Cards:not(.GuaranteeCards) > div:nth-child(2),
  .GuaranteeCards > div:nth-child(3),
  .GuaranteeCards > div:nth-child(4) {
    display: none;
  }

  .GuaranteeCards.Cards-4 .Link,
  .GuaranteeCards:not(.Cards-0) .Link {
    grid-column: 2;
  }
}

@media screen and (max-width: 767px) {
  .Title {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .Cards {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .GuaranteeCards > div {
    height: 128px;
  }

  .Link,
  .GuaranteeCards .Link {
    height: 390px;
    grid-row: unset;
  }
}
