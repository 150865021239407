.Title {
  display: flex;
  gap: 12px;
  padding-top: 72px;
  padding-bottom: 64px;
}

.Wrapper {
  display: grid;
  grid-template-columns: 3fr 9fr;
  gap: 20px;
  margin-top: 24px;
  position: relative;
  z-index: 11;
}

.Cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 20px;
}

@media screen and (max-width: 1439px) {
  .Wrapper {
    grid-template-columns: 4fr 8fr;
  }

  .Cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1023px) {
  .Wrapper {
    grid-template-columns: 100%;
    margin-top: 16px;
    gap: 32px;
  }
}

@media screen and (max-width: 767px) {
  .Title {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .Cards {
    grid-template-columns: 100%;
    gap: 32px;
  }
}
