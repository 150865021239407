.TopWrapper,
.BottomWrapper {
  width: 100%;
  position: absolute;

  overflow: hidden;
}

.TopWrapper {
  height: 789px;

  right: 0;
  top: 700px;
}

.BottomWrapper {
  height: 999px;

  left: 0;
  bottom: 0;
  transform: translateX(-22%);
}

.Top,
.Bottom {
  z-index: 1;
}

.Top {
  width: 1440px;
}

.Bottom {
  width: 2324px;
}

@media screen and (max-width: 1439px) {
  .WrapperTop {
    top: 1400px;
  }

  .WrapperBottom {
    bottom: 0;
  }
}

@media screen and (max-width: 1023px) {
  .WrapperTop {
    top: 1100px;
  }

  .WrapperBottom {
    bottom: -300px;
  }
}

@media screen and (max-width: 767px) {
  .WrapperTop {
    width: 1000px;
    top: 943px;
    right: -100px;
  }

  .WrapperBottom {
    transform: translateX(-26%);
    bottom: -250px;
  }
}
