.Section,
.Cards {
  display: flex;
  flex-direction: column;
}

.Section {
  gap: 40px;
  align-items: center;
}

.Section > h2 {
  text-align: center;
}

.Cards {
  gap: 32px;
  width: 100%;
}

.Links {
  display: flex;
  gap: 24px;
}

.Link {
  color: var(--black);
  background-color: var(--primary-green);
  padding: 12px 32px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.PdfIcon {
  width: 24px;
}

@media (max-width: 767px) {
  .Section {
    gap: 32px;
  }

  .Cards {
    gap: 20px;
  }

  .Links {
    flex-direction: column;
    width: 100%;
  }

  .Link {
    width: 100%;
  }
}
