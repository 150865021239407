.Contacts {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Contacts > a {
  padding: 14px 16px;

  display: flex;
  align-items: center;
}

.Contacts > a svg {
  margin-left: 20px;
  width: 24px;
  height: 24px;
}

.Contacts > a > svg > path {
  stroke: white;
}

.LinkIcon {
  display: block;
  width: 24px;
  height: 24px;
}
