.SubTitle {
  margin-bottom: 24px;
}

.TextBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  max-width: 572px;
}

@media screen and (max-width: 1439px) {
  .SubTitle {
    line-height: 24px;
  }
}

@media screen and (max-width: 1023px) {
  .TextBlock {
    max-width: 487px;
  }
}

@media screen and (max-width: 767px) {
  .SubTitle {
    max-width: unset;
    font-size: 24px;
    line-height: 24px;
  }

  .TextBlock {
    max-width: unset;
    padding-bottom: 8px;
  }
}
