.Wrapper {
  position: relative;
  width: 100%;
}

.SelectBtn {
  border: none;
  border-bottom: 1px solid var(--gray-200);
  padding: 10px 24px;
  background-color: transparent;
  justify-content: space-between;
  color: var(--gray-100);
  text-transform: uppercase;
  width: 100%;
  text-align: left;
}

.SelectBtn svg {
  transform: rotate(90deg);
  width: 20px;
  height: 20px;
  transition: 0.3s transform;
}

.SelectBtn svg path {
  stroke: var(--white);
}

.SelectedBtn svg {
  transform: rotate(-90deg);
}

.List {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 50;
  background-color: var(--gray-400);
  color: var(--white);
  padding: 0 16px 16px;
  width: 100%;
  text-transform: uppercase;

  max-height: 175px;
  overflow: scroll;

  box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.25);

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.List::-webkit-scrollbar { 
  display: none;
}


.List li {
  padding: 10px 24px;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .List {
    max-height: 135px;
  }
}
