.Brand {
  width: 100%;
}

.Logo {
  background-color: var(--black);
  width: 100%;
  height: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Logo img {
  width: 225px;
  height: 180px;
  object-fit: contain;
}

.Title {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Title svg {
  width: 56px;
  height: 56px;
}

.Link svg path {
  transition: 0.3s stroke;
  stroke: var(--primary-green);
}

.Link:hover svg path,
.Link:focus svg path {
  stroke: var(--primary-green);
}

@media screen and (max-width: 1439px) {
  .Logo img {
    width: 185px;
    height: 144px;
  }
}

@media screen and (max-width: 1023px) {
  .Logo {
    height: 220px;
  }

  .Logo img {
    width: 132px;
    height: 103px;
  }
}

@media screen and (max-width: 767px) {
  .Logo {
    height: 220px;
  }

  .Logo img {
    width: 132px;
    height: 103px;
  }
}
