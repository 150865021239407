.Main,
.Text {
  display: flex;
  flex-direction: column;
}

.Main {
  height: 100%;
  justify-content: space-between;
}

.Text ul li {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  margin-bottom: 16px;
}

.Text p {
  margin-bottom: 16px;
}

.Text ul li::before {
  flex-shrink: 0;
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0L10.448 2.02789L13.9282 4L13.896 8L13.9282 12L10.448 13.9721L7 16L3.552 13.9721L0.0717969 12L0.104 8L0.0717969 4L3.552 2.02789L7 0Z' fill='%23CFD04C'/%3E%3C/svg%3E%0A")
    center no-repeat;
}

.Text > *:last-child {
  color: var(--gray-200);
  margin-bottom: 0;
}

@media (max-width: 1023px) {
  .Main {
    gap: 40px;
    width: 87%;
  }
}

@media (max-width: 767px) {
  .Main {
    gap: 32px;
    width: 100%;
  }

  .Wrap > div > div:nth-child(2) {
    display: block;
  }
}
