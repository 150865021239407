.Section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.ContactCard {
  width: 100%;
  display: flex;
  gap: 50px;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--primary-green);
  justify-content: space-between;
  padding: 24px;
}

.ContactCard p {
  color: var(--white)
}

.ContactCard svg path {
  stroke: var(--primary-green);
}

.TopCard {
  display: grid;
  grid-template-columns: 1fr 24px;
  align-items: flex-start;
  width: 100%;
}

.ContactInfos {
  width: 100%;
}

.ContactInfos br {
  display: none;
}

@media (max-width: 1439px) {
  .ContactCard {
    gap: 46px;
  }

  .ContactCard br {
    display: block;
  }
}

@media (max-width: 1023px) {
  .ContactCard {
    gap: 32px;
  }
}

@media (max-width: 767px) {
  .Section {
    grid-template-columns: 1fr;
  }

  .ContactCard {
    gap: 40px;
    min-height: 276px;
  }
}