.btn {
  height: 60px;
  border: 1px solid transparent;
  width: 196px;
  background-color: var(--primary-green);
  color: var(--black);
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.48px;
  transition: 0.3s background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.btn.block {
  width: 100%;
}

.btn:hover {
  background-color: var(--primary-green-100);
}

.btn:focus {
  border-color: var(--gray-300);
}

.btn:disabled {
  background-color: var(--gray-300);
}

.text-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  text-transform: uppercase;
  letter-spacing: 0.48px;
  color: var(--gray-300);
  transition: 0.3s all;
}

.text-btn svg {
  width: 24px;
  height: 24px;
}