.TextContent {
  width: 100%;
  height: 100%;
  max-width: 572px;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  align-items: self-start;
  flex-direction: column;
  min-height: 460px;
}

@media screen and (max-width: 1439px) {
  .TextContent {
    max-width: 572px;
    min-height: 312px;
  }
}

@media screen and (max-width: 1023px) {
  .TextContent {
    gap: 40px;
    min-height: auto;
  }
}

@media screen and (max-width: 767px) {
  .TextContent {
    gap: 32px;
  }
}
