.Services {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.Service {
  display: grid;
  grid-template-columns: 3fr 4fr 5fr;
  gap: 24px 20px;
  padding-top: 24px;
  border-top: 1px solid var(--border-color);
}

.Text {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.Link {
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--primary-green);
  margin-top: 16px;
}

.Link svg {
  width: 24px;
  height: 24px;
}

.Link svg path {
  stroke: var(--primary-green);
}

.Link:hover {
  color: var(--primary-green-100);
}

.Link:hover svg path {
  stroke: var(--primary-green-100);
}

@media screen and (max-width: 1439px) {
  .Service {
    grid-template-columns: 3fr 5fr 4fr;
  }
}

@media screen and (max-width: 1023px) {
  .Service {
    grid-template-columns: 1fr 1fr;
  }

  .Service picture {
    grid-column: 1;
  }

  .Service > div {
    grid-row: 1/3;
    grid-column: 2;
  }
}

@media screen and (max-width: 767px) {
  .Services {
    gap: 32px;
  }

  .Service {
    grid-template-columns: 100%;
    padding-top: 20px;
  }

  .Service picture {
    grid-column: initial;
  }

  .Service > div {
    grid-row: initial;
    grid-column: initial;
  }
}
