.Wrapper {
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  row-gap: 40px;
}

.Title {
  text-align: center;
}

.Brands {
  padding-bottom: 158px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 48px 20px;
}

.BrandsSmall {
  justify-self: center;

  width: calc(926px);
}

.BrandTitle {
  margin: 40px 0;
}

@media screen and (max-width: 1023px) {
  .BrandsSmall {
    width: 656px;
  }
}

@media screen and (max-width: 767px) {
  .Brands {
    padding-bottom: 118px;
    grid-template-columns: 1fr;
  }

  .BrandsSmall {
    width: 100%;
  }

  .BrandsSmall {
    --brands-width: 656px;
  }

  .BrandTitle {
    margin: 32px 0;
  }
}
