.Title {
  margin-bottom: 80px;
}

.FirstBlock {
  display: grid;
  grid-template-columns: auto 690px;
  align-items: flex-start;
  width: 100%;
  gap: 28px;

  margin-bottom: 80px;
}

.Text {
  white-space: pre-line;
}

.Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.MainImage {
  width: 100%;
  grid-column: 1 / 3;
}

.SecondBlock {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 80px;
  column-gap: 28px;
}

@media (max-width: 1439px) {
  .Title {
    margin-bottom: 56px;
    max-width: 324px;
  }

  .FirstBlock {
    grid-template-columns: auto 470px;
    gap: 20px;
  }

  .Image {
    height: auto;
  }
}

@media (max-width: 1023px) {
  .FirstBlock, .SecondBlock  {
    grid-template-columns: 1fr;
  }

  .FirstBlock {
    gap: 80px;
  }

  .MainImage {
    grid-column: 1;
    grid-row: 2;
  }
}

@media (max-width: 767px) {
  .Title {
    margin-bottom: 40px;
    max-width: 209px;

  }

  .FirstBlock {
    gap: 40px;
    margin-bottom: 42px;
  }

  .SecondBlock  {
    row-gap: 40px;
  }
}