.Section {
  display: grid;
  grid-auto-flow: row;
  gap: 64px;
}

.Text {  
  white-space: break-spaces;
  max-width: 808px;

  display: grid;
  grid-auto-flow: row;
  gap: 1em;
}

.Images {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fit, 460px);
  gap: 20px;
}

.Images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1439px) {
  .Images {
    grid-template-rows: repeat(auto-fit, 313px);
  }
}

@media (max-width: 1023px) {
  .Section {
    gap: 40px;
  }

  .Images {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fit, 436px);
  }

  .SecondImage {
    display: none;
  }
}

@media (max-width: 767px) {
  .Section {
    gap: 24px;
  }

  .Images {
    grid-template-rows: repeat(auto-fit, 270px);
  }
}
