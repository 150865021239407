.Section {
  display: grid;
  grid-template-columns: 3fr 2fr 7fr;
  gap: 20px;
  align-items: flex-start;
  width: 100%;
}

.ImageBlock {
  width: 100%;
  max-width: 335px;
  grid-column: 1;
  display: flex;
}

.ImageBlock picture {
  width: 100%;
}

.Content {
  display: grid;
  gap: 24px;
  width: 100%;
  grid-column: 3;
}

@media screen and (max-width: 1439px) {
  .Section {
    grid-template-columns: 4fr 1fr 7fr;
  }
}

@media screen and (max-width: 1023px) {
  .Section {
    grid-template-columns: 3fr 5fr;
  }

  .ImageBlock {
    height: 100%;
  }

  .Content {
    grid-column: 2;
  }
}

@media screen and (max-width: 767px) {
  .Section {
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
  }

  .ImageBlock {
    max-width: unset;
    min-height: 270px;
    max-height: 460px;
  }
}