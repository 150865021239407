.ServiceType {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.ServiceType > ul {
  list-style: inherit;
  padding-left: 24px;
  padding-top: 8px;
}

.ServiceType h4 {
  line-height: 26px;
}

@media (max-width: 1439px) {
  .ServiceType h4 {
    line-height: 22px;
  }
}

@media (max-width: 1023px) {
  .ServiceType h4 {
    line-height: 22px;
  }

  .ServiceType {
    gap: 32px;
  }

  .ServiceType > ul {
    padding-top: 0px;
  }
}

@media (max-width: 767px) {
  .ServiceType {
    gap: 24px;
  }
}
