.PromoText {
  color: var(--primary-green);
}

.Text {
  width: var(--width-7);
}

.Text p {
  margin-bottom: 20px;
}

.Contacts {
  display: grid;
  grid-template-columns: 6fr 6fr;
  align-items: center;
  gap: 20px;
}

.ContactsContent {
  display: grid;
  gap: 20px;
}

.Title {
  padding: 40px 0;
}

.Images {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.About {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: var(--primary-green);
  color: var(--black);
}

.About h2 {
  margin-bottom: 40px;
}

.AboutContent {
  display: grid;
  grid-template-columns: 5fr 7fr;
  align-items: center;
  gap: 20px;
}

.AboutContent p {
  margin-bottom: 20px;
  max-width: 70%;
}

@media screen and (max-width: 1439px) {
  .Contacts {
    align-items: flex-start;
  }

  .ContactsContent h2 {
    margin-bottom: 20px;
  }

  .Images {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1023px) {
  .SimpleTitles h1 {
    max-width: 75%;
  }

  .Text {
    width: 75%;
  }

  .Contacts {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .Images {
    grid-template-columns: repeat(2, 1fr);
  }

  .TextBlock {
    max-width: 487px;
  }
}

@media screen and (max-width: 767px) {
  .SimpleTitles h1 {
    max-width: 100%;
  }

  .SimpleTitles h1:first-child {
    margin-bottom: 16px;
  }

  .Text {
    width: 100%;
  }

  .ContactsContent h2 {
    margin-bottom: 16px;
  }

  .Contacts {
    gap: 36px;
  }

  .Images {
    grid-template-columns: repeat(1, 1fr);
  }
}
