.Contacts {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 8px;
}

.Contacts > a {
  margin-right: 16px;
  white-space: nowrap;
}


.Contacts > a svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.Contacts > a > svg > path {
  stroke: white;
}

.LinkIcon {
  display: block;
  width: 24px;
  height: 24px;
}

.Number {
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .Contacts {
    padding-left: 0;
  }

  .Contacts > a,
  .Contacts > a svg {
    margin-right: 0;
  }

  .Number {
    display: none;
  }
}
