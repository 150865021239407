.Wrapper {
  display: grid;
  grid-template-columns: 7fr 1fr 4fr;
  gap: 20px;
  padding-top: 48px;
  align-items: flex-start;
}

.Section {
  grid-column: 1;
}

.Text {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.Content p {
  margin-bottom: 32px;
}

.Content ul,
.Content ol {
  list-style: inside;
  margin-bottom: 32px;
  padding-left: 32px;
}

.Content a {
  color: var(--primary-green);
}

@media screen and (max-width: 1439px) {
  .Wrapper {
    grid-template-columns: 7fr 1fr 2fr 2fr;
  }

  .Wrapper header {
    grid-column: 1/4;
  }
}

@media screen and (max-width: 1023px) {
  .Wrapper {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .Wrapper {
    padding-top: 32px;
  }

  .Text {
    gap: 32px;
  }
}
