.Icon {
  width: 100%;
  height: 56px;
  display: flex;
  margin-bottom: 24px;
}

.Icon svg {
  margin-left: auto;
  height: 100%;
  width: auto;
  object-fit: contain;
}
