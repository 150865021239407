.Section > h2 {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (max-width: 1439px) {
  .Cards {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .Section > h2 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .Section {
    gap: 32px;
  }

  .Cards {
    grid-template-columns: 1fr;
  }
}
