.List {
  background-color: var(--black);
}

.Item {
  display: flex;
  padding: 16px 18px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--gray-400);
  white-space: normal;
}

.Item svg {
  width: 24px;
  height: 24px;
}