.Title {
  padding: 40px 0;
  text-align: left;
}

.List {
  display: grid;
  grid-auto-flow: row;
  gap: 32px;
}

.ButtonWrapper {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}

.Button {
  height: 48px;
  width: 302px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .List {
    gap: 24px;
  }
}

@media screen and (max-width: 767px) {
  .List {
    margin-left: calc(-1 * var(--padding-container));
    margin-right: calc(-1 * var(--padding-container));
  }
}
