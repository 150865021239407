.Filters {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media screen and (max-width: 1023px) {
  .Filters {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--gray-400);
    z-index: 20;
    padding: 24px var(--padding-container);
    overflow: auto;
  }

  .Filters > div:nth-last-child(2) {
    border-bottom: none;
  }

  .MobileBtn {
    color: var(--gray-300);
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .MobileHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .Apply {
    margin-top: auto;
  }

  .Close {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .Filters {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .MobileHeader {
    margin-bottom: 16px;
  }
}
