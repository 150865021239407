.Content {
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 4fr 3fr;
  gap: 40px 20px;
  align-items: flex-start;
}

.MainPicture {
  width: 100%;
  height: 100%;
  grid-row: 1/3;
  grid-column: 1;
  display: flex;
}

.MainPicture img {
  height: 100%;
  object-fit: cover;
}

.ListBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  grid-row: 1;
  grid-column: 2;
}

.SubPicture {
  width: 100%;
  grid-row: 2;
  grid-column: 2;
  display: flex;
}

.TextBlock {
  width: 100%;
  grid-row: 2;
  grid-column: 3;
}

@media screen and (max-width: 1439px) {
  .Content {
    grid-template-columns: 1fr 1fr;
  }
  
  .TextBlock {
    grid-row: 2;
    grid-column: 2;
  }

  .SubPicture {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .Content {
    grid-template-columns: 6fr 2fr;
  }
  
  .MainPicture {
    grid-row: 3;
    grid-column: 1/3;
  }
  
  .ListBlock {
    grid-row: 1;
    grid-column: 1;
  }
  
  .TextBlock {
    grid-row: 2;
    grid-column: 1;
  }
}

@media screen and (max-width: 767px) {
  .Content {
    grid-template-columns: 1fr;
  }
  
  .MainPicture {
    grid-row: 3;
    grid-column: 1;
  }
  
  .ListBlock {
    grid-row: 1;
    grid-column: 1;
  }
  
  .TextBlock {
    grid-row: 2;
    grid-column: 1;
  }
}
