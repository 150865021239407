.New {
  background-color: var(--black);
  color: var(--white);
  display: block;
  height: 100%;

  transition: 0.3s all;
}

.New:hover {
  background-color: var(--primary-green);
  color: var(--black);
}

.New.Small {
  color: var(--gray-100);
}

.Picture {
  height: 406px;
  width: 100%;
  display: block;
}

.Small .Picture {
  height: 286px;
}

.Picture img {
  object-position: center;
}

.Bottom {
  padding: 20px 24px 24px;
}

.Date {
  margin-bottom: 5px;
}

.Description {
  margin-top: 20px;
}

@media screen and (max-width: 1439px) {
  .Bottom {
    padding-left: 12px;
    padding-right: 12px;
  }

  .Picture {
    height: 286px;
  }

  .Small .Picture {
    height: 201px;
  }
}

@media screen and (max-width: 1023px) {
  .New {
    height: 100%;
  }

  .Picture,
  .Small .Picture {
    height: 296px;
  }

  .Description {
    display: none;
  }
}
