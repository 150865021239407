.Section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 40px;
}

.LeftSide,
.GuaranteeList {
  display: flex;
  flex-direction: column;
}

.LeftSide {
  justify-content: space-between;
}

.GuaranteeList {
  gap: 64px;
}

.Explanation {
  max-width: 453px;
  color: var(--gray-300);
}

.ExplanationSmall {
  display: none;
}

@media (max-width: 1439px) {
  .LeftSide {
    justify-content: end;
    gap: 148px;
  }
}

@media (max-width: 1023px) {
  .Section {
    grid-template-columns: 1fr;
    gap: 64px;
    margin-top: 32px;
  }

  .LeftSide {
    order: -1;
  }

  .Explanation {
    display: none;
  }

  .ExplanationSmall {
    display: block;
    margin-top: -40px;
  }
}

@media (max-width: 767px) {
  .Section {
    gap: 48px;
  }

  .GuaranteeList {
    gap: 40px;
  }

  .ExplanationSmall {
    margin-top: -28px;
  }
}
