.Pagination {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.Btn {
  color: var(--gray-200);
  gap: 4px;
  transition: 0.3s color;
}

.Btn:first-child svg {
  transform: rotate(180deg);
}

.Btn:hover {
  color: var(--gray-100);
}

.Btn:focus {
  outline: var(--outline);
}

.Btn:disabled {
  color: var(--gray-300);
}

.Btn:disabled svg path {
  stroke: var(--gray-300);
}

@media screen and (max-width: 1439px) {
  .Pagination {
    position: relative;
    margin-left: auto;
    width: fit-content;
  }
}

@media screen and (max-width: 1023px) {
  .Pagination {
    width: 100%;
    justify-content: space-between;
  }
}
