.Card {
  position: relative;
}

.NewTag {
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 20px;
  display: block;
  padding: 10px;
  background-color: var(--white);
  color: var(--black);
  text-transform: uppercase;
}

.Image {
  height: 330px;
  margin-bottom: 10px;
}

.Small {
  height: 234px;
}

.Content {
  background-color: var(--black);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media screen and (max-width: 1439px) {
  .NewTag {
    top: 16px;
    left: 16px;
  }

  .Image {
    height: 224px;
  }
}

@media screen and (max-width: 1023px) {
  .Image {
    height: 232px;
  }
}

@media screen and (max-width: 767px) {
  .Image {
    height: 210px;
  }
}
