.Section {
  display: grid;
  grid-template-columns: 5fr 7fr;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
  padding-top: 72px;
}

.TextContent {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.Tabs {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.ImageBlock {
  width: 100%;
}

.ImageBlock {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1439px) {
  .Section {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .Section {
    grid-template-columns: 1fr;
    gap: 32px;
    padding-top: 76px;
  }
}


@media screen and (max-width: 767px) {
  .Section {
    gap: 24px;
    padding-top: 44px;
  }
  
  .TextContent {
    gap: 32px;
  }
}
