.Card {
  display: flex;
  position: relative;
  height: 250px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.Image > img {
  width: 100%;
}

.Image {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
}

.Content {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  transition: background 0.4s, color 0.4s;
  background: var(--black);
  color: var(--white);
}

.Content > p {
  width: 100%;
}

.IconBlock {
  width: 56px;
  height: 56px;
}

.Card svg {
  stroke: var(--white);
  transition: 0.4s;
}

.Card:hover > div {
  background: rgba(207, 208, 76, 0.80);
  color: var(--black);
}

.Card:hover svg {
  stroke: var(--black);
}

@media screen and (max-width: 1023px) {
  .Card {
    height: 200px;
  }
}
