.Section {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.Section > div:first-child {
  margin-bottom: 0;
}

.TextSection {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 48px 20px;
}

.Main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 48px;
}

.Section > div > div:last-child {
  position: relative;
  height: fit-content;
}

.BottomSide {
  display: grid;
  grid-template-columns: 5fr 4fr 3fr;
  column-gap: 20px;
  row-gap: 16px;
}

.BottomSide > *:first-child {
  grid-row: 1/3;
}

.BottomSide > *:nth-child(3) {
  grid-column: 2;
}

.BottomText {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.BottomSide > .BottomText,
.BottomSide > p {
  max-width: 572px;
}

.Main > h4 {
  line-height: 26px;
}

.Img {
  width: 100%;
  height: 100%;
  display: flex;
}

.Img img {
  object-fit: cover;
}

@media (max-width: 1439px) {
  .Section {
    gap: 64px;
  }

  .TextSection {
    grid-template-columns: 2fr 1fr;
  }

  .BottomSide {
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
  }

  .BottomSide > *:first-child {
    grid-row: 1/2;
  }

  .BottomSide > *:nth-child(4) {
    grid-row: 2;
  }

  .Main {
    width: 92%;
    max-width: 572px;
  }

  .Main > h4 {
    line-height: 22px;
  }
}

@media (max-width: 1023px) {
  .Main {
    gap: 40px;
  }

  .TextSection {
    grid-template-columns: 1fr; 
  }

  .Section > div > div:nth-child(2) img {
    height: 100%;
  }

  .BottomSide {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }

  .BottomSide > *:nth-child(1) {
    grid-row: 3;
    margin-top: 8px;
  }

  .BottomSide > *:nth-child(2) {
    grid-column: 1;
    grid-row: 1;
  }

  .BottomSide > *:nth-child(3) {
    display: none;
  }

  .BottomSide > *:nth-child(4) {
    grid-column: 1;
    grid-row: 2;
  }
}

@media (max-width: 767px) {
  .Section {
    gap: 48px;
  }
  .Section > div:first-child > div:nth-child(2) {
    display: block;
  }

  .BottomSide > *:nth-child(1) {
    margin-top: 0;
  }

  .Main {
    width: 100%;
  }

  .TextSection {
    gap: 36px;
  }
}
