.Layout {
  padding: 40px 0;
  align-items: center;
  overflow: auto;
}

.Container {
  width: 895px;

  background-image: var(--noise);
  background-repeat: repeat;
  background-color: var(--gray-400);

  position: relative;

  padding: 60px;
  height: max-content;
}

@media screen and (max-width: 1023px) {
  .Container {
    width: 695px;
  }
}

@media screen and (max-width: 767px) {
  .Layout {
    padding: 0;
    align-items: flex-start;
  }

  .Container {
    padding: 40px 16px;

    width: 100%;
    height: 100%;
  }
}