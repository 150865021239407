.Sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 108px;
}

.Sidebar svg {
  transform: rotate(180deg);
}

@media(max-width: 1439px) {
  .Sidebar > ul {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .Sidebar {
    gap: 32px;
  }
}
