.Wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-column: 3;
}

.Wrapper h4 {
  margin-bottom: 28px;
}

@media screen and (max-width: 1439px) {
  .Wrapper {
    grid-column: 3/5;
  }
}

@media screen and (max-width: 1023px) {
  .Wrapper {
    display: none;
  }
}
