.List {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  overflow: auto;
  height: 100%;
  background-color: var(--black);
  padding: 24px;
}

.Item {
  background-color: var(--gray-400);
  color: var(--white);
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  white-space: pre-line;
}

.Item h6 {
  font-weight: 700;
}

.Item a {
  width: fit-content;
}

.Selected {
  background-color: var(--primary-green);
  color: var(--black);
}

@media screen and (max-width: 1439px) {
  .List {
    height: initial;
    max-height: 320px;
  }
}

@media screen and (max-width: 767px) {
  .List {
    max-height: 476px;
  }
}