.Title {
  max-width: 926px;
  margin-bottom: 80px;
}

.Text {
  white-space: pre-line;
}

.FirstBlock {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 28px;

  margin-bottom: 80px;
}

.FirstBlockImage, .SecondBlockImage {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.2);
}

.Video {
  width: 100%;
  aspect-ratio: 2 / 1;

  margin-bottom: 80px;
}

.SecondBlock {
  display: grid;
  grid-template-columns: 690px auto;
  gap: 20px;
}

@media (max-width: 1439px) {
  .Title {
    margin-bottom: 60px;
  }

  .SecondBlock {
    grid-template-columns: 470px auto;
  }

  .SecondBlockImage {
    height: auto;
  }
}

@media (max-width: 1023px) {
  .Title {
    margin-bottom: 40px;
  }

  .FirstBlock, .SecondBlock {
    grid-template-columns: 1fr;
  }

  .SecondBlock {
    gap: 80px;
  }

  .FirstBlockImage {
    display: none;
  }

  .SecondBlockImage {
    grid-row: 2;
  }
}

@media (max-width: 767px) {
  .FirstBlock, .Video {
    margin-bottom: 64px;
  }

  .SecondBlock {
    gap: 64px;
  }
}
