.Group {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--border-color-2);
}

.Title {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.Title svg {
  transform: rotate(90deg);
  width: 24px;
  height: 24px;
  transition: 0.3s transform;
}

.TitleOpened svg {
  transform: rotate(-90deg);
}

.Ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.Ul li {
  display: flex;
}

.Ul li > span {
  color: var(--white);
  margin-left: 4px;
}
