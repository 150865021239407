.Wrapper {
  display: grid;
  grid-auto-flow: row;
  gap: 24px;

  white-space: pre-line;
}

.Wrapper svg {
  width: 22px;
  height: 22px;
}

.Contacts {
  display: grid;
  grid-auto-flow: row;
  gap: 12px;
}

.Contacts a {
  width: fit-content;
  display: flex;
  align-items: center;
}

.Contacts svg {
  margin-right: 10px;
}

.Icon svg path {
  fill: var(--primary-green);
}