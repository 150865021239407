.Section {
  display: grid;
  grid-template-columns: 5fr 4fr 3fr;
  column-gap: 20px;
  row-gap: 64px;
  width: 100%;
}

.MainBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.BottomText {
  display: grid;
  grid-template-columns: 4fr 3fr;
  gap: 20px;
  grid-column: 2/4;
}

.MainBlock > p:last-child {
  width: 80%;
}

@media (max-width: 1439px) {
  .Section {
    grid-template-columns: 1fr 1fr;
  }

  .MainBlock > p:last-child {
    width: 100%;
  }

  .SecondImage {
    grid-column: 1;
  }

  .BottomText {
    grid-template-columns: 1fr;
  }

  .BottomText > p:last-child {
    margin-top: auto;
  }
}

@media (max-width: 1023px) {
  .Section {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }

  .MainBlock,
  .BottomText {
    width: 74%;
  }

  .MainBlock {
    gap: 32px;
  }

  .SecondImage {
    display: none;
  }

  .BottomText {
    grid-column: 1;
    gap: 24px;
  }
}

@media (max-width: 767px) {
  .Section {
    gap: 32px;
  }

  .MainBlock,
  .BottomText {
    width: 100%;
  }

  .MainBlock {
    gap: 24px;
  }

  .BottomText {
    gap: 20px;
  }
}
