*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

p {
  font-weight: 400;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
}

ol,
ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

input {
  border: none;
  width: 100%;
  outline: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  padding: 0;
  background-color: transparent;
}

svg,
img {
  vertical-align: middle;
  width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
