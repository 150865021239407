.Nav {
  height: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
}

.List {
  height: 100%;
  display: flex;
  text-transform: uppercase;
  letter-spacing: 0.48px;
  color: var(--white);
  white-space: nowrap;
}

.SubListWrapper {
  width: 100%;
  background-color: var(--black);

  position: absolute;
  left: 0;
  top: 100%;
}

.SubList {
  width: 100%;
  padding: 32px 32px 7px;
}

.SubList > ul {
  display: flex;
  flex-wrap: wrap;

  :not(:last-child) {
    margin-right: 80px;
  }
}

.SubItem {
  border-bottom: 1px solid var(--gray-400);
  margin-bottom: 25px;
}

.SubItem button,
.SubItem a {
  display: block;
  padding: 14px 0;
}

.SubItem svg {
  width: 24px;
  height: 24px;
}

.List a,
.MenuButton {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 31px;
}

.SubButton {
  margin-bottom: 26px;
}

.MenuButton > svg,
.SubButton > svg {
  width: 24px;
  height: 24px;
}

.MenuButton > svg {
  margin-left: 8px;
  transition: .3s all;
}

.List a:hover,
.MenuButton:hover,
.MenuButton[data-selected="true"] {
  background-color: var(--black);
}

.MenuButton[data-selected="true"] > svg {
  transform: rotate(180deg);
}

.Contacts {
  display: none;
}

@media screen and (max-width: 1439px) {
  .Nav {
    position: absolute;
    top: 100%;
    left: 0;
    height: fit-content;
    z-index: 2;
    background: var(--gray-400);
    width: 100%;
    display: flex;
    gap: 24px;
  }

  .List a,
  .MenuButton {
    padding: 40px 31px;
  }
}

@media screen and (max-width: 1023px) {
  .List {
    width: 100%;
    justify-content: center;
  }

  .List a,
  .MenuButton {
    padding: 40px 26px;
  }
}

@media screen and (max-width: 767px) {
  .Nav {
    display: none;
  }
}
