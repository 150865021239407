.Section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 160px 16px;
  position: relative;
  z-index: 3;
}

.Code {
  font-size: 105px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 4px;
}

.Section svg {
  width: 303px;
}

.Section p {
  margin-top: 40px;
  margin-bottom: 32px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 1439px) {
  .Code {
    font-size: 88px;
  }

  .Section svg {
    width: 270px;
  }
}

@media screen and (max-width: 767px) {
  .Section {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .Code {
    font-size: 64px;
  }

  .Section svg {
    width: 270px;
  }
}
