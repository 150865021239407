.Section {
  display: grid;
  grid-auto-flow: row;
  gap: 16px;
  text-decoration: none;
}

.Links {
  display: flex;
  flex-direction: column;
}

.Link {
  text-decoration: none;
}