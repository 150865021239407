.tab {
  border: 1px solid var(--gray-200);
  height: 46px;
  color: var(--primary-green);
  padding: 0 32px;
  display: flex;
  align-items: center;
  width: fit-content;
  flex-shrink: 0;
  word-break: keep-all;
}

.tab.active {
  background-color: var(--primary-green);
  border-color: var(--primary-green);
  color: var(--black);
}

.tab.block {
  width: 100%;
}
