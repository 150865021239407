.Label {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.Label input[type=checkbox] {
  position: absolute;
  appearance: none;
}

.Checkbox {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid var(--gray-200);
  background-position: center;
  background-size: 16px;
  cursor: pointer;
  transition: 0.3s border-color;
  flex-shrink: 0;
}

.Label input[type=checkbox]:checked + .Checkbox {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.9231 4.54837L6.73163 11.8022L3 8.03819L3.54366 7.48982L6.73163 10.7055L13.3794 4L13.9231 4.54837Z' fill='%23CFD04C'/%3E%3C/svg%3E");
}

.Checkbox:hover {
  border-color: var(--white);
}

.Label input[type=checkbox]:focus + .Checkbox {
  border-color: var(--primary-green);
}

.Label.Error .Checkbox {
  border-color: var(--red);
}

.Label p {
  color: var(--gray-300);
}

.Label a {
  text-decoration: underline;
}
