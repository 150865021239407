.Nav {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--gray-400);

  width: 100%;
}

.List {
  padding-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  letter-spacing: 0.48px;
  color: var(--white);
  white-space: nowrap;
}

.Item {
  width: 100%;

  display: flex;
  justify-content: space-between;
  padding: 16px 18px;
  text-transform: uppercase;
}

.Item svg {
  width: 24px;
  height: 24px;
}

.Item[data-selected="true"] > svg {
  transform: rotate(180deg);
}

.SubList {
  overflow: hidden;
  max-height: 56px;
}

.SubList[data-selected="true"] {
  max-height: 1000px;
}

.Contacts {
  margin-bottom: 32px;
}

.ModalButton[data-subList="true"],
.List[data-subList="true"],
.Contacts[data-subList="true"] {
  display: none;
}

.ModalButton {
  width: calc(100% - 2 * var(--padding-container));
  margin: 12px var(--padding-container);
}

@media screen and (min-width: 768px) {
  .Nav {
    display: none;
  }
}
