.Map {
  height: 808px;
  grid-column: 2;
}

@media screen and (max-width: 1439px) {
  .Map {
    height: 934px;
    margin-left: calc(-1 * var(--padding-container));
    margin-right: calc(-1 * var(--padding-container));
    grid-column: 1;
  }
}

@media screen and (max-width: 1023px) {
  .Map {
    height: 843px;
  }
}

@media screen and (max-width: 767px) {
  .Map {
    visibility: hidden;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 428px;
    width: 100%;
    padding: 0 16px 16px;
    z-index: 3;
    background-color: var(--black);
  }

  .OpenedMobileMap {
    visibility: visible;
  }
}
