.Section {
  display: grid;
  grid-auto-flow: row;
  gap: 40px;
  align-items: flex-start;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Swiper {
  min-width: 0;
}

.Slide {
  height: auto;
}

@media screen and (max-width: 767px) {
  .Section {
    align-items: stretch;
    gap: 32px;
  }

  .Header {
    display: grid;
    grid-auto-flow: row;
    gap: 16px;
  }

  .Header,
  .Swiper {
    width: 100%;
  }

  .Slide {
    height: auto;
  }
}
