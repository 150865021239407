.bold {
  font-weight: 700;
}

.center {
  text-align: center;
}

.h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -2.24px;
  text-transform: uppercase;
}

.h2 {
  font-size: 42px;
  line-height: 1;
  text-transform: uppercase;
}

.h3 {
  font-size: 30px;
  line-height: 36px;
}

.h4 {
  font-size: 24px;
  line-height: 28px;
}

.p1 {
  font-size: 20px;
  line-height: 22px;
}

.p2 {
  font-size: 16px;
  line-height: 18px;
}

.p1.uppercase {
  line-height: 24px;
}

.p2.uppercase {
  letter-spacing: 0.48px;
  line-height: 20px;
}

.p3 {
  font-size: 14px;
  line-height: 18px;
}

.p4 {
  font-size: 12px;
  line-height: 16px;
}

.uppercase {
  text-transform: uppercase;
}

.link {
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.6px;
}

.icon-link {
  display: flex;
  gap: 4px;
  align-items: center;
}

.icon-link svg {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

@media screen and (max-width: 1439px) {
  .h1 {
    font-size: 48px;
    letter-spacing: -1.92px;
  }

  .h2 {
    font-size: 40px;
  }

  .h3 {
    font-size: 24px;
    line-height: 28px;
  }

  .h4 {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1023px) {
  .h1 {
    font-size: 40px;
    letter-spacing: -1.6px;
  }

  .h2 {
    font-size: 32px;
  }
}

@media screen and (max-width: 767px) {
  .h1 {
    font-size: 32px;
    letter-spacing: -1.28px;
  }

  .h2 {
    font-size: 28px;
  }

  .h3 {
    font-size: 22px;
    line-height: 26px;
  }

  .p1 {
    font-size: 18px;
    line-height: 20px;
  }

  .link {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.48px;
  }
}
